<template>
  <v-app id="root">
    <v-app-bar
      app
      dense
      dark
      color="primary"
      class="app-header"
      :style="mini ? 'left: 60px !important' : 'left: 256px !important'"
    >
      <v-toolbar-title style="width: 70%">
        <v-row no-gutters>
          <v-col align-self="center" class="title ml-2 text-truncate">{{ name }}</v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-model="menu" :close-on-content-click="false" offset-y class="red">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="text-normal d-none d-sm-flex" v-bind="attrs" v-on="on">
            {{ username }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
          <v-btn text class="text-normal d-flex d-sm-none" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300" style="right: 0px">
          <v-toolbar dense dark color="primary" elevation="2">
            <v-spacer></v-spacer>
            <v-toolbar-title color="primary">Mi perfil</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="12">
                <v-icon :size="80">mdi-account-circle</v-icon>
              </v-col>
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold">Nombre</span>
              </v-col>
              <v-col cols="12" class="py-0">
                {{ fullname }}
              </v-col>
              <v-col cols="12" class="py-0 mt-4">
                <span class="font-weight-bold">Roles</span>
              </v-col>
              <v-col cols="12">
                <v-chip v-for="(item, idx) in roles" :key="idx" small>{{ item }}</v-chip>
              </v-col>
              <v-col cols="12" class="py-0 mt-4">
                <span class="font-weight-bold">Versión</span>
              </v-col>
              <v-col cols="12" class="py-0">
                {{ version }}
              </v-col>
            </v-row>
            <v-divider class="my-3 mt-5"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-btn small color="primary" class="text-normal" @click="logout()">Cerrar sesión</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="mini"
      permanent
      dense
      hidden
      dark
      color="primary"
      class="app-sidemenu"
      :style="mini ? 'width: 60px !important' : ''"
    >
      <v-list dense nav>
        <v-btn icon @click="activeNav()">
          <v-icon>{{ mini ? "mdi-menu" : "mdi-close" }}</v-icon>
        </v-btn>
        <template v-for="item in items">
          <template v-if="!item.children">
            <v-tooltip right :key="item.title" :disabled="!mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-if="!item.admin || (item.admin && isAdmin)"
                  v-bind="attrs"
                  v-on="on"
                  :key="item.title"
                  :to="item.path"
                  link
                  color="white"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>
          <template v-if="!mini && item.children">
            <v-tooltip right :key="item.title" :disabled="!mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-group :key="item.title" :prepend-icon="item.icon" color="white" v-bind="attrs" v-on="on">
                  <template v-slot:activator>
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
                  <template v-if="!mini">
                    <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.path" link>
                      <v-list-item-icon>
                        <v-icon>mdi-circle-small</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ child.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>
          <template v-if="mini && item.children">
            <v-menu :key="item.title + 'menu'" right offset-x style="z-index: 1">
              <template v-slot:activator="{ on, attrs }">
                <v-list class="py-0" v-bind="attrs" v-on="on">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
              <v-list class="py-0">
                <v-list-item-title
                  style="background-color: black; font-weight: bold; color: white"
                  dense
                  class="pa-5 py-4"
                >
                  {{ item.title }}
                </v-list-item-title>
                <v-divider />
                <div v-for="(child, i) in item.children" :key="i">
                  <v-list-item dense :to="item.children[i].path" link>
                    <v-list-item-icon>
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-list>
      <v-divider></v-divider>
      <template v-slot:append>
        <v-list dense nav>
          <v-list-item link color="white" @click.native="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main class="grey lighten-5" :style="mini ? 'padding-left: 60px !important' : 'padding-left: 256px !important'">
      <router-view></router-view>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "root",
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    logo: require("@/assets/logo_menu.png"),
    username: "",
    fullname: "",
    roles: [],
    path: "/api/logout",
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    drawer: false,
    hover: false,
    mini: false,
    menu: false,
    items: [
      { icon: "mdi-home", title: "Inicio", path: "/home" },
      {
        icon: "mdi-account-multiple",
        title: "Usuarios",
        path: "/users",
        admin: true,
      },
      {
        icon: "mdi-book-open-variant",
        title: "Catálogos",
        path: "/catalogues",
        admin: true,
      },
      { icon: "mdi-calendar-star", title: "Campañas", path: "/campaigns" },
      {
        title: "Ajustes",
        path: "",
        show: true,
        icon: "mdi-cog",
        children: [
          {
            title: "Proveedores",
            path: "/settings/providers",
            icon: "mdi-domain",
          },
        ],
      },
    ],
  }),
  mounted() {
    this.check();
  },
  computed: {
    isAdmin: function () {
      let roles = this.$session.get("roles");
      if (roles.indexOf("admin") >= 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    check() {
      this.username = this.$session.get("user");
      this.fullname = this.$session.get("name");
      this.roles = this.$session.get("roles");
    },
    logout() {
      this.loading = true;
      if (this.$session.exists()) {
        this.axios
          .post(this.host + this.path)
          .then((response) => {
            this.$session.destroy();
            this.$router.push("/login");
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$router.push("/login");
      }
    },
    home() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },
    activeNav() {
      this.mini = !this.mini;
    },
  },
};
</script>

<style scoped>
.app-header {
  z-index: 99;
}
.app-sidemenu {
  z-index: 99;
}
.text-normal {
  text-transform: none !important;
}
#root > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  margin-left: 12px;
}
</style>
